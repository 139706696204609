import VueI18n from 'vue-i18n'
import Vue from "vue";

Vue.use(VueI18n)

const messages = {
    en: {
        "Sākums": "Home",
        "Rakstīšana": "Typing",
        "Locīšana": "Inflection",
        "Vārdi teikumā": "Gap Filling",
        "inflections_title": "Decline the word {word}",
        "Pārbaudīt": "Check",
        "Parādīt atbildes": "Show answers",
        "Cits": "Other",
        "check_result": "Correct answers: {correct} out of {total} ({percentage}%)",
        "Ievieto doto vārdu pareizajā locījumā": "Insert the given word in the correct form",
        "Citi teikumi": "More sentences",
        "Datorraksts": "Typed",
        "Rokraksts": "Handwritten",
        "Pārraksti tekstu": "Rewrite the text",
        "Turpināt": "Continue",
        "Atzīmēt visu": "Check all",
        "Noņemt visu": "Clear all",
        "tag.": "pres.",
        "tagadnes": "present",
        "Tagadne": "Present",
        "nāk.": "fut.",
        "nākotnes": "future",
        "Nākotne": "Future",
        "pag.": "past",
        "pagātnes": "past",
        "Pagātne": "Past",
        "1. pers.": "1st pers.",
        "1. persona": "1st person",
        "2. pers.": "2nd pers.",
        "2. persona": "2nd person",
        "3. pers.": "3rd pers.",
        "3. persona": "3rd person",
        "vsk.": "sg.",
        "vienskaitļa": "singular",
        "dsk.": "pl.",
        "daudzskaitļa": "plural",
        "nom.": "nom.",
        "nominatīvs": "nominative",
        "ģen.": "gen.",
        "ģenetīvs": "genitive",
        "dat.": "dat.",
        "datīvs": "dative",
        "akuz.": "acc.",
        "akuzatīvs": "accusative",
        "lok.": "loc.",
        "lokatīvs": "locative",
        "vok.": "voc.",
        "vokatīvs": "vocative",
        "Lietvārds": "Noun",
        "Deklinācija": "Declension",
        "Darbības vārds": "Verb",
        "Konjugācija": "Conjugation",
        "Vietniekvārds": "Pronoun",
        "Tips": "Type",
        "Īpašības vārds": "Adjective",
        "Kopā ar": "Together with",
        "Skaitļa vārds": "Numeral",
        "1. deklinācija": "1st declension",
        "2. deklinācija": "2nd declension",
        "3. deklinācija": "3rd declension",
        "4. deklinācija": "4th declension",
        "5. deklinācija": "5th declension",
        "6. deklinācija": "6th declension",
        "1. konjugācija": "1st conjugation",
        "2. konjugācija": "2nd conjugation",
        "3. konjugācija": "3rd conjugation",
        "nekārtns": "irregular",
        "Personu": "Personal",
        "1. deklinācijas lietvārdu": "a noun of the 1st declension",
        "2. deklinācijas lietvārdu": "a noun of the 2nd declension",
        "3. deklinācijas lietvārdu": "a noun of the 3rd declension",
        "4. deklinācijas lietvārdu": "a noun of the 4th declension",
        "5. deklinācijas lietvārdu": "a noun of the 5th declension",
        "6. deklinācijas lietvārdu": "a noun of the 6th declension",
        "Atgriezeniskums": "Reflexivity",
        "tiešs": "direct",
        "atgriezenisks": "reflexive",
        "Locīt kopā ar lietvārdu": "Decline together with the noun",
        'Vienskaitlis': 'Singular',
        'Daudzskaitlis': 'Plural',
        'funding': 'Financed by Latvian Council of Science Grant "Development of Learner corpus of Latvian: methods, tools and applications" (No. lzp-2018/1-0527).',
        'home-title': 'Welcome to the Latvian language self-assessment website!',
        'home-l1': 'The exercises provided on this website are meant for anyone studying basic Latvian (level A1 or A2). They are generated automatically based on data from the Latvian learner corpus <a href="http://lava.korpuss.lv" target="_blank">“LaVA”</a> as well as other corpora.',
        'home-l2': 'The exercises were developed by Ilze Auziņa, Inga Kaija, Kristīne Levāne-Petrova, Kristīne Pokratniece, and Roberts Darģis. If you find a mistake in the tasks or have any other comments, you can contact us at ilze.auzina at lumii.lv!',
        'home-l3': 'This website was created as a part of the project "Development of Learner corpus of Latvian: methods, tools and applications" (No. lzp-2018/1-0527). For more information about the project, see the <a href="http://lava.korpuss.lv/publications" target="_blank">website of the corpus</a>.',
        'home-l4': '<strong>We wish you success in your journey of learning Latvian!</strong>'
    },
    lv: {
        'Sākums': 'Sākums',
        'Rakstīšana': 'Rakstīšana',
        'Locīšana': 'Locīšana',
        'Vārdi teikumā': 'Vārdi teikumā',
        'inflections_title': 'Izloki vārdu {word}',
        'Pārbaudīt': 'Pārbaudīt',
        'Parādīt atbildes': 'Parādīt atbildes',
        'Cits': 'Cits',
        'check_result': 'Pareizas {correct} no {total} atbildēm ({percentage}%)',
        'Ievieto doto vārdu pareizajā locījumā': 'Ievieto doto vārdu pareizajā locījumā',
        'Citi teikumi': 'Citi teikumi',
        'Datorraksts': 'Datorraksts',
        'Rokraksts': 'Rokraksts',
        'Pārraksti tekstu': 'Pārraksti tekstu',
        'Turpināt': 'Turpināt',
        'Atzīmēt visu': 'Atzīmēt visu',
        'Noņemt visu': 'Noņemt visu',
        'tag.': 'tag.',
        'tagadnes': 'tagadnes',
        'Tagadne': 'Tagadne',
        'nāk.': 'nāk.',
        'nākotnes': 'nākotnes',
        'Nākotne': 'Nākotne',
        'pag.': 'pag.',
        'pagātnes': 'pagātnes',
        'Pagātne': 'Pagātne',
        '1. pers.': '1. pers.',
        '1. persona': '1. persona',
        '2. pers.': '2. pers.',
        '2. persona': '2. persona',
        '3. pers.': '3. pers.',
        '3. persona': '3. persona',
        'vsk.': 'vsk.',
        'vienskaitļa': 'vienskaitļa',
        'Vienskaitlis': 'Vienskaitlis',
        'Daudzskaitlis': 'Daudzskaitlis',
        'dsk.': 'dsk.',
        'daudzskaitļa': 'daudzskaitļa',
        'nom.': 'nom.',
        'nominatīvs': 'nominatīvs',
        'ģen.': 'ģen.',
        'ģenetīvs': 'ģenetīvs',
        'dat.': 'dat.',
        'datīvs': 'datīvs',
        'akuz.': 'akuz.',
        'akuzatīvs': 'akuzatīvs',
        'lok.': 'lok.',
        'lokatīvs': 'lokatīvs',
        'vok.': 'vok.',
        'vokatīvs': 'vokatīvs',
        'Locīt kopā ar lietvārdu': 'Locīt kopā ar lietvārdu',
        'funding': 'Finansējuma avots: FLPP projekts “Latviešu valodas apguvēju korpusa izveide: metodes, rīki un izmantojums” (Nr. lzp-2018/1-0527).',
        'home-title': 'Esiet sveicināti latviešu valodas pašpārbaudes uzdevumu vietnē!',
        'home-l1': 'Šie uzdevumi ir paredzēti visiem, kas mācās latviešu valodu pamatlīmenī (A1 vai A2). Tie tiek ģenerēti automātiski, izmantojot datus no latviešu valodas apguvēju korpusa <a href="http://lava.korpuss.lv" target="_blank">“LaVA”</a> un citiem valodas korpusiem.',
        'home-l2': 'Uzdevumu veidotāji ir Ilze Auziņa, Inga Kaija, Kristīne Levāne-Petrova, Kristīne Pokratniece un Roberts Darģis. Ja atrodat kādu kļūdu uzdevumos vai vēlaties ar mums sazināties cita iemesla dēļ, rakstiet uz ilze.auzina at lumii.lv!',
        'home-l3': 'Uzdevumi ir tapuši projektā “Latviešu valodas apguvēju korpusa izveide: metodes, rīki un izmantojums” (Nr. lzp-2018/1-0527). Plašāka informācija par projektu <a href="http://lava.korpuss.lv/publications" target="_blank">korpusa mājaslapā</a>.',
        'home-l4': '<strong>Veiksmi un panākumus mācībās!</strong>'

    }
}

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: 'lv', // set locale
    fallbackLocale: 'lv',
    messages, // set locale messages
})

export default i18n
