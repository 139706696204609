import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VTooltip from 'v-tooltip'

import App from './App.vue'
import router from './router'
import i18n from './i18n'

Vue.use(VTooltip)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false

// const messages = {
//   en: {
//     'Sākums': 'Home',
//     'Rakstīšana': 'Writing',
//     'Locīšana': 'Inflections',
//     'Vārdi teikumā': 'Words in Sentences'
//   },
//   lv: {
//     'Sākums': 'Sākums',
//     'Rakstīšana': 'Rakstīšana',
//     'Locīšana': 'Locīšana',
//     'Vārdi teikumā': 'Vārdi teikumā'
//   }
// }
//
// // Create VueI18n instance with options
// const i18n = new VueI18n({
//   locale: 'lv', // set locale
//   messages, // set locale messages
// })



new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
