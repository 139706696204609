import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n from '../i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'home',
    component: Home
  },
  {
    path: 'writing',
    name: 'writing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Spelling.vue')
  },
  {
    path: 'inflection_menu',
    name: 'inflection_menu',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InflectionsMenu.vue')
  },
  {
    path: 'inflection/:filter?',
    name: 'inflection',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Inflections.vue')
  },
  {
    path: 'sentences_menu',
    name: 'sentences_menu',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SentencesMenu.vue')
  },
  {
    path: 'sentences/:filter?',
    name: 'sentences',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Sentences.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes: [
      {
        path: '/:locale',
        component: {
          render: h => h('router-view')
        },
        beforeEnter: (to, from, next) => {
          const locale = to.params.locale
          if (!['lv', 'en'].includes(locale)) return next('lv')
          if (locale && i18n.locale !== locale) {
            i18n.locale = locale;
          }
          return next()
        },
        children: routes,
      },
      {
        path: '*',
        redirect() { return 'lv' }
      }
  ],
  // routes,
  mode: 'history',
  // linkActiveClass: "active",
  linkExactActiveClass: "active",
})

export default router
