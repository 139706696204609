<template>
  <div class="home text-start">
    <div class="h3" v-t="'home-title'"></div>

    <p v-html="$t('home-l1')"></p>

    <p v-html="$t('home-l2')"></p>

    <p v-html="$t('home-l3')"></p>

    <p v-html="$t('home-l4')"></p>

    <hr class="mt-4" />

    <p class="text-center footer_images">
      <a href="http://ailab.lv/" target="_blank"><img src="@/assets/ailab.gif" style="height:70px;" /></a>
      <img src="@/assets/flpp.jpg" />
      <img src="@/assets/vpp_lv.jpg" />
    </p>

    <p class="text-center" v-t="'funding'"></p>

  </div>
</template>

<style scoped>
.footer_images img
{
  max-width: 100%;
  height: 90px;
  padding: 5px;
}
</style>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>
